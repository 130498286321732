import { Edit } from "@react-admin/ra-rbac"
import { OrganizationalUnitForm } from "./OrganizationalUnitForm"

export const OrganizationalUnitEdit = () => {
  return (
    <Edit>
      <OrganizationalUnitForm />
    </Edit>
  )
}
