import { Datagrid, List } from "@react-admin/ra-rbac"
import {
  CreateButton,
  ExportButton,
  FilterButton,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin"
import { NIL } from "uuid"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { BulkDeleteAndTransferButton } from "../Components/BulkDeleteAndTransferButton"
import { OrganizationalUnitField } from "../Components/OrganizationalUnitField"
import { OrganizationalUnitInput } from "../Components/OrganizationalUnitInput"
import { HH2ImportButton } from "../HH2/HH2ImportButton"
import { useEnabledModules } from "../hooks/useEnabledModules"
import { ProcoreImportButton } from "../Procore/ProcoreImportButtons"
import { projectsExporter } from "./projectsExporter"

const ListActions = () => {
  const { isProcoreEnabled, isLoading } = useEnabledModules()
  if (isLoading) return null
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      {isProcoreEnabled && <ProcoreImportButton resource="projects" />}
      {false && <HH2ImportButton resource="jobs" />}
    </TopToolbar>
  )
}
export const ProjectList = () => {
  const [organization_id] = useOrganizationId()
  const { areOrganizationalUnitsEnabled, isLoading } = useEnabledModules()
  if (isLoading) return null
  const projectFilters = [
    <TextInput
      key="project_name"
      label="Search"
      source="project_name@ilike"
      alwaysOn
    />,
  ]
  if (areOrganizationalUnitsEnabled) {
    projectFilters.push(
      <OrganizationalUnitInput
        source="organizational_unit_id"
        key="organizational_unit_id"
      />
    )
  }
  return (
    <List
      actions={<ListActions />}
      filters={projectFilters}
      exporter={projectsExporter}
      sort={{ field: "updated_at", order: "DESC" }}
      filter={{ "project_id@neq": NIL, "deleted_at@is": null, organization_id }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={<BulkDeleteAndTransferButton />}
      >
        <TextField source="project_name" />
        {areOrganizationalUnitsEnabled && (
          <OrganizationalUnitField source="organizational_unit_id" />
        )}
      </Datagrid>
    </List>
  )
}
