import { Show, SimpleShowLayout } from "@react-admin/ra-rbac"
import { TextField } from "react-admin"

export const OrganizationalUnitShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  )
}
