import { AutocompleteInput, ReferenceInput } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { useEnabledModules } from "../hooks/useEnabledModules"

export function OrganizationalUnitInput(props) {
  const { areOrganizationalUnitsEnabled, isLoading } = useEnabledModules()
  const [organization_id] = useOrganizationId()
  if (!areOrganizationalUnitsEnabled || isLoading) return null

  return (
    <ReferenceInput
      {...props}
      reference="organizational_units"
      filter={{
        organization_id,
        "deleted_at@is": "NULL",
      }}
    >
      <AutocompleteInput
        source="name"
        optionText="name"
        filterToQuery={(query) => ({ "name@ilike": query })}
      />
    </ReferenceInput>
  )
}
