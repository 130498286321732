import { SimpleForm } from "@react-admin/ra-rbac"
import { TextInput, required } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

export const OrganizationalUnitForm = (props) => {
  const [organization_id] = useOrganizationId()

  return (
    <SimpleForm defaultValues={{ organization_id }} {...props}>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  )
}
