import { Create } from "react-admin"
import { OrganizationalUnitForm } from "./OrganizationalUnitForm"

export const OrganizationalUnitCreate = () => {
  return (
    <Create>
      <OrganizationalUnitForm />
    </Create>
  )
}
