import { useGetList } from "react-admin"
import { useOrganization } from "../../hooks/useOrganizationId"

export function useEnabledModules() {
  const { data, isLoading: isLoadingOrg } = useOrganization()
  const { organization_id } = data ?? {}
  // TODO: Move this into enabled_modules
  const reportingEnabled = data?.reporting_enabled ?? []
  const { data: enabledModules, isLoading: isLoadingEnabledModules } =
    useGetList(
      "enabled_modules",
      {
        filter: { organization_id },
      },
      { enabled: organization_id !== undefined }
    )
  const isServiceEnabled =
    enabledModules?.some((module) => module.module === "service") ?? false
  const isProcoreEnabled =
    enabledModules?.some((module) => module.module === "procore") ?? false
  const areOrganizationalUnitsEnabled =
    enabledModules?.some(
      (module) => module.module === "organizational_units"
    ) ?? false
  const isLoading = isLoadingOrg || isLoadingEnabledModules
  return {
    isServiceEnabled,
    isProcoreEnabled,
    reportingEnabled,
    areOrganizationalUnitsEnabled,
    isLoading,
  }
}
