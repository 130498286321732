import { SimpleForm } from "@react-admin/ra-rbac"
import { CountryCode } from "libphonenumber-js"
import { useMemo } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  Labeled,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin"
import PhoneInput from "react-phone-number-input/mobile"
import "react-phone-number-input/style.css"
import {
  useOrganizationId,
  useOrganizationalUnitId,
} from "../../hooks/useOrganizationId"
import { OrganizationalUnitInput } from "../Components/OrganizationalUnitInput"
import { useRoles } from "../roles"

export function CrewMemberForm(props) {
  const [organization_id] = useOrganizationId()
  const filter = useMemo(() => {
    return {
      organization_id,
      "deleted_at@is": "NULL",
    }
  }, [organization_id])
  const [organizational_unit_id] = useOrganizationalUnitId()

  return (
    <SimpleForm
      defaultValues={{ organization_id, organizational_unit_id }}
      {...props}
    >
      <TextInput source="name" />
      <PhoneNumberInput label="Phone Number" source="phone_number" />
      <OrganizationalUnitInput source="organizational_unit_id" />
      <TextInput
        source="email"
        type="email"
        parse={(value) => value.trim().toLowerCase()}
      />
      <BooleanInput
        source="enable_tool_add_notifications"
        defaultValue={false}
      />
      <RoleInput required />
      <ReferenceInput
        reference="projects"
        source="default_project_id"
        filter={filter}
      >
        <AutocompleteInput
          source="project_name"
          optionText="project_name"
          filterToQuery={(query) => ({ "project_name@ilike": query })}
        />
      </ReferenceInput>
    </SimpleForm>
  )
}

export function RoleInput(props) {
  const roles = useRoles()
  return (
    <SelectInput
      {...props}
      defaultValue="crew"
      source="role"
      emptyText="Select a role"
      choices={roles}
    />
  )
}

const PhoneNumberInput = ({ label, source }) => {
  return (
    <Labeled label={label} isRequired>
      <TextInput
        label=""
        source={source}
        InputProps={{
          inputComponent: (props) => (
            // @ts-expect-error sss
            <PhoneInput
              style={{
                display: "flex",
              }}
              {...props}
              defaultCountry={navigator.language.split("-")[1] as CountryCode}
              numberInputProps={{
                style: {
                  borderColor: "transparent",
                  borderWidth: "0px",
                },
              }}
            />
          ),
        }}
      />
    </Labeled>
  )
}
