import { Show, SimpleShowLayout } from "@react-admin/ra-rbac"
import {
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin"
import { OrganizationalUnitField } from "../Components/OrganizationalUnitField"
import { PercentField } from "../Components/PercentField"
import { HH2ImportButton } from "../HH2/HH2ImportButton"
import { ProcoreImportButton } from "../Procore/ProcoreImportButtons"
import { ActionButtonsWithExport } from "../Tool/ActionButtonsWithExport"
import { ToolDatagrid } from "../Tool/ToolList"
import { useEnabledModules } from "../hooks/useEnabledModules"

const ProjectActions = () => {
  const record = useRecordContext()
  if (!record) return null
  return (
    <ActionButtonsWithExport exportTarget="project_id">
      {record.procore_project_id && (
        <ProcoreImportButton
          resource="cost_codes"
          projectId={record.procore_project_id}
          companyId={record.procore_company_id}
        />
      )}
      {record.hh2_id && (
        <HH2ImportButton resource="cost_codes" jobId={record.hh2_id} />
      )}
    </ActionButtonsWithExport>
  )
}

export const ProjectShow = () => {
  const { areOrganizationalUnitsEnabled, isLoading } = useEnabledModules()
  if (isLoading) return null
  return (
    <Show actions={<ProjectActions />}>
      <SimpleShowLayout>
        <TextField source="project_name" />
        {areOrganizationalUnitsEnabled && (
          <OrganizationalUnitField source="organizational_unit_id" />
        )}
        <PercentField source="project_cap_percentage" />
        <PercentField source="project_discount_percentage" />
        <NumberField source="small_tools_value" />
        <PercentField source="small_tools_cap_percentage" />
        <ReferenceField source="default_cost_code_id" reference="cost_codes">
          <TextField source="cost_code_name" />
        </ReferenceField>
        <ReferenceManyField
          reference="tools"
          target="project_id"
          label={false}
          filter={{ "deleted_at@is": null }}
          sort={{ field: "updated_at", order: "DESC" }}
        >
          <ToolDatagrid bulkActionButtons={false} rowClick="show" />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}
