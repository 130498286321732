import jsonExport from "jsonexport/dist"
import { downloadCSV, Exporter } from "react-admin"

export const projectsExporter: Exporter = async function (
  projects: any[],
  fetchRelatedRecords
  // dataProvider
) {
  // const clients = await fetchRelatedRecords(
  //   projects,
  //   "client_id",
  //   "clients"
  // )
  const organizationUnits = await fetchRelatedRecords(
    projects,
    "organizational_unit_id",
    "organizational_units"
  )
  const projectsForExport = await Promise.all(
    projects.map(async (project) => {
      const { project_name } = project
      return {
        projectName: project_name,
        organizationalUnit:
          organizationUnits[project?.organizational_unit_id]?.name,
      }
    })
  )

  const csv = await jsonExport(projectsForExport, {
    headers: ["projectName", "organizationalUnit"], // order fields in the export
  })
  downloadCSV(csv, "projects")
}
