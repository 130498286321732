import jsonExport from "jsonexport/dist"
import { downloadCSV, Exporter } from "react-admin"
import { prettyPhoneFromPhoneNumber } from "../prettyPhoneFromPhoneNumber"
import { roles } from "../roles"

export const crewMembersExporter: Exporter = async (
  crewMembers,
  fetchRelatedRecords
): Promise<void> => {
  const organizationUnits = await fetchRelatedRecords(
    crewMembers,
    "organizational_unit_id",
    "organizational_units"
  )
  const crewMembersForExport = crewMembers.map((crewMember) => {
    const {
      name,
      phone_number,
      email,
      role,
      organizational_unit_id,
      employee_id,
    } = crewMember
    return {
      name,
      phone: prettyPhoneFromPhoneNumber({ phone_number }),
      email,
      role: roles.find(({ id }) => id === role)?.name,
      employeeId: employee_id,
      organizationalUnit: organizationUnits[organizational_unit_id]?.name,
    }
  })
  const csv = await jsonExport(crewMembersForExport, {
    headers: [
      "name",
      "phone",
      "email",
      "role",
      "employeeId",
      "organizationalUnit",
    ], // order fields in the export
  })
  downloadCSV(csv, "crewMembers")
}
