import { Datagrid, List } from "@react-admin/ra-rbac"
import { Pagination, TextField, TextInput } from "react-admin"

const OrganizationalUnitFilters = [
  <TextInput key="name" label="Search" source="name@ilike" alwaysOn />,
]

export const OrganizationalUnitList = () => {
  return (
    <List
      filters={OrganizationalUnitFilters}
      sort={{ field: "updated_at", order: "DESC" }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
