import ExcelJS from "exceljs"
import { snakeCase } from "lodash-es"
import { useStore } from "react-admin"
import streamSaver from "streamsaver"
import { i18nProvider } from "../i8n/i8nProvider"
import { labelFromInactiveStatus } from "./inactiveStatuses"
import { labelFromToolState } from "./toolStates"

const useCurrentColumns = () => {
  const [availableColumns] = useStore(
    "preferences.tools.datagrid.availableColumns"
  )
  // omit columns is always just the default omit list
  const [omitColumns] = useStore("preferences.tools.datagrid.omit")
  // columns property is null if the user has not yet customized the columns
  const [numericCols] = useStore("preferences.tools.datagrid.columns")
  if (numericCols) {
    return availableColumns?.filter(({ index }) => numericCols.includes(index))
  }
  if (!omitColumns) return availableColumns
  return availableColumns?.filter(({ source }) => !omitColumns.includes(source))
}

export function useToolsExporter() {
  const [availableColumns] = useStore(
    "preferences.tools.datagrid.availableColumns"
  )
  const currentColumns = useCurrentColumns()?.map(({ source }) => source)

  return async function exporter(tools: any[], fetchRelatedRecords: any) {
    const projects = await fetchRelatedRecords(tools, "project_id", "projects")
    const senders = await fetchRelatedRecords(
      tools,
      "sender_id",
      "crew_members"
    )
    const organizations = await fetchRelatedRecords(
      tools,
      "organization_id",
      "organizations"
    )
    const organizationUnits = await fetchRelatedRecords(
      tools,
      "organizational_unit_id",
      "organizational_units"
    )
    const columns = [
      { source: "primary_photo_link" },
      ...availableColumns.filter(
        ({ source }) =>
          ![
            "tool_photo",
            // "primary_photo",
            "label_photo",
            "additional_photo_0",
            "additional_photo_1",
            "additional_photo_2",
            "additional_photo_3",
            "additional_photo_4",
            "transfer_photo",
          ].includes(source)
      ),
    ]

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("Tools")
    // pulled this from excel after adjusting
    if (currentColumns.includes("primary_photo")) {
      worksheet.properties.defaultRowHeight = 38.25
    }

    worksheet.columns = columns.map(
      ({ source }): Partial<ExcelJS.Column> => ({
        header: i18nProvider.translate(`resources.tools.fields.${source}`, {}),
        key: source,
        width: 20,
        hidden: !currentColumns.includes(source.replace("_link", "")),
        numFmt: source.includes("value") ? "$#,##" : undefined,
      })
    )

    const rows = tools
      .filter((tool) => tool.deleted_at == null)
      .map((tool) => {
        const {
          organization_id,
          sender_id,
          project_id,
          created_at,
          updated_at,
          state,
          inactive_status,
          value,
          quantity_value,
          primary_photo,
        } = tool
        const organization = organizations[organization_id]
        const sender = senders[sender_id]
        const project = projects[project_id]

        const idColumn = snakeCase(organization.id_column)
        const itemId = tool[idColumn]
        if (itemId != null) {
          tool.item_id =
            (organization.id_prefix ?? "") +
            itemId +
            (organization.id_suffix ?? "")
        }
        //=IMAGE(source, [alt_text], [sizing] (3 = custom), [height], [width])
        // Note _xlfn is required https://github.com/exceljs/exceljs/issues/2699
        tool.primary_photo = {
          formula: `_xlfn.IMAGE("${primary_photo.src.replace(
            "/object/",
            "/render/image/"
          )}?width=50&height=50", "${itemId}", 3, 50, 50)`,
        }
        tool.primary_photo_link = {
          formula: `_xlfn.HYPERLINK("${primary_photo.src.replace(
            "/object/",
            "/render/image/"
          )}", "Show")`,
        }
        tool.sender_id = sender ? sender.name : ""
        tool.project_id = project ? project.project_name : ""
        tool.created_at = new Date(created_at)
        tool.updated_at = new Date(updated_at)
        tool.state = labelFromToolState(state)
        tool.inactive_status = labelFromInactiveStatus(inactive_status)
        tool.value = floatFromCurrency(value)
        tool.quantity_value = floatFromCurrency(quantity_value)
        tool.organizational_unit =
          organizationUnits[tool.organizational_unit_id]?.name
        worksheet.addRow(tool)

        return columns.map(({ source }) => tool[source])
      })

    worksheet.addTable({
      name: "Tools",
      ref: `A1:${worksheet.columns.length}1`,
      headerRow: true,
      totalsRow: true,
      style: {
        theme: null,
      },
      columns: columns.map(({ source }: { index: number; source: string }) => ({
        name: i18nProvider.translate(`resources.tools.fields.${source}`, {}),
        filterButton: true,
        totalsRowFunction: ["quantity_value"].includes(source)
          ? "sum"
          : undefined,
      })),
      rows,
    })

    const fileStream = streamSaver.createWriteStream("tools.xlsx", {
      // size: (await workbook.xlsx.writeBuffer()).byteLength,
    })
    const writer = fileStream.getWriter()
    await workbook.xlsx.write(writer)
    writer.close()
  }
}

function floatFromCurrency(value: string) {
  if (value == null) return 0.0
  return parseFloat(value.replaceAll(",", "").replaceAll("$", "")) || 0.0
}
